import React, { useState, useEffect, useRef } from 'react';
import { 
  Phone, 
  MessageCircle, 
  Wrench,
  Shield,
  ThumbsUp,
  X,
  MapPin
} from 'lucide-react';
import AnimatedMasonryReviews from './AnimatedMasonryReviews';
import MasonryGallery from './MasonryGallery';
import { Helmet } from 'react-helmet';

const ToyTeknik = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeSection, setActiveSection] = useState('hero');
  const [showMoreGallery, setShowMoreGallery] = useState(false);

  // References for sections
  const sections = useRef({
    hero: null,
    services: null,
    'reviews-intro': null,
    gallery: null,
    posts: null,
    contact: null
  });

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };

    const observerOptions = {
      root: null,
      rootMargin: '-100px 0px',  // Offset by header height
      threshold: 0.2, // Lowered threshold for better detection
    };

    const observerCallback = (entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id);
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    Object.values(sections.current).forEach(section => {
      if (section) observer.observe(section);
    });

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      if (observer) observer.disconnect();
    };
  }, []);

  const services = [
    {
      title: "Kombi Tamiri ve Bakımı",
      description: "Kombinizdeki tüm arızaları çözüyoruz ve her değişen parça için 1 yıl garanti sunuyoruz. Bakım hizmetimizle kombinizin performansını artırabilirsiniz.",
      image: "https://ToyTeknik.com/resimler/kombi.jpg"
    },
    {
      title: "Çamaşır Makinesi Tamiri",
      description: "Gürültülü çalışma, rulman değişimi gibi sorunları çözüme kavuşturuyoruz. Cihazınızı kapıdan alıp tamir ediyor ve yine evinize teslim ediyoruz.",
      image: "https://ToyTeknik.com/resimler/camasir.jpg"
    },
    {
      title: "Bulaşık Makinesi Tamiri",
      description: "Yıkamama, su almama ve ısıtmama gibi tüm sorunlar için güvenilir çözümler sağlıyoruz. Fiyat almak için bize ulaşabilirsiniz.",
      image: "https://ToyTeknik.com/resimler/bulasik.jpg"
    }
  ];

  const reviews = [
    { name: "Mcan K.", text: "Mehmet usta tam bir dahiyane. Kombimdeki nedenini bilemediğimiz bir arızayı doktor edasıyla teşhis ederek kısa sürede tedavi etti. İlgi ve samimiyetine de ayrıca tesekkür ederiz." },
    { name: "Birol Aydın", text: "İşinde çok başarılı, müşteri memnuniyetini en üstte tutan, maddiyattan önce kalite & memnuniyeti ön planda tutan bir işletme... Biz, iki farklı sorun için destek aldık. Çok memnun kaldık, herkese de tavsiye ediyoruz... İşi, ehline bırakın sözünün direk karşılığı..." },
    { name: "Turgut Songur", text: "Hem işini çok iyi bilmesi (Mehmet Usta) hemde fiyat konusunda çok memnun kaldım bundan sonraki arızalarda çağıracağım ve muhatap olacağım tek yerdir herşey için çok teşekkür ederim" },
    { name: "Cengiz Güvenir", text: "Kombi ve beyaz eşya tamirinde ustalık ve kişilik olarak takdir eder başarılarının devamını dilerim." },
    { name: "Salime Yutaz", text: "Yorumlara bakarak ulaştım, işinin ehli usta. Artık tamirat durumunda tek adresimiz." },
    { name: "Serkan Ceylan", text: "Birçok defa Toy Teknik'ten hizmet aldım. Profesyonellik bu olsa gerek. Teşekkürler Toy Teknik!" },
    { name: "Ayhan Tetik", text: "Mehmet beyın iş ahlakını ve deneyımı tecrubesıne guvenerek herkese tavsiye ederim.kendısıne kolaylıklar dılerım" },
    { name: "Serkan CEYLAN", text: "Birçok defa Toy Teknik servisinden hizmet aldım. Çok memnun kaldım. Profesyonellik bu olsa gerek. Teşekkürler Toy Teknik." },
    { name: "Aliriza Yildirim", text: "Kombi ve Beyaz eşyam arıza yaptı halledemediler diyen tüm herkese Toy tekniğin numarasını veririm.Eyvallah ustam ellerine sağlık." },
    { name: "Sinan Guler", text: "Yaptığın işçilik çok güzel.şu piyasada güvenilir insan bulmak çok zor.aldığın her kuruş helal olsun.bol kazançlar dilerim" },
    { name: "Hakan HASTÜRK", text: "İşinin ehli, ustalığı mükemmel hakkından fazlasında gözü olmayan bir usta tavsiye ederim" },
    { name: "Abdullah Şen", text: "Çok iyi bir ustadır.Teredüt etmeden arayabilirsiniz.İşini düzgün yapan nadir ustalardan biridir." },
    { name: "Bülent Dipli", text: "Merhabalar TOY Teknik Ekibine çok teşekkür ederim.ilgi ve alakaniza işinizi temiz ve uygun yapıyorsunuz çok memnun kaldım.Herkese tavsiye ederim." },
    { name: "Ülkiye Korkmaz", text: "Yapımı zor olan kombimiz bile özveriyle yapıldı. Biz çok memnun kaldık kışlık bakımınıda buraya yaptıracağız. Çok teşekkür ederiz. Herkese tavsiye ederim." },
    { name: "Melda Şimal Şevval Duru", text: "Hızlı dönüş sağlayıp sorunu çözüme kolayca ulaştırdığınız için teşekkürler" },
    { name: "Sakir Sahin", text: "Teşekkür ederim." },
    { name: "Muhammet Ağçay", text: "İşinin ehli ve yardımsever olmasından dolayı Toy Tekniğe teşekkür ederim" },
    { name: "Nazli Mirjafari", text: "Harika çok teşekkür ederiz" },
    { name: "Zeki Sağdıç", text: "Kaliteli ve güvenli işçilik" },
    { name: "Kadir Aslan", text: "İşinin ehli güvenilir gözünüz arkada kalmaz tavsiye ederim" },
    { name: "Nesrullah Yildirim", text: "Temiz, kaliteli, güvenilir bir işçilik, gerçekten çok memnun kaldık." },
    { name: "Ayhan Yildirim", text: "Arkadaşlar kombi, ve bulaşık makinam arızalıydı arkadaşların tavsiyesiyle Mehmet ustaya ulaştım randevulaştıgımız gün ve saatinde geldi arıza mızı giderdi işinde frofesyonel titiz güvenilir kendisine huzurunuzda tekrar teşekkür ediyorum." },
    { name: "Özgür Ak", text: "Mehmet usta karakteni işe yansıtıyor ve temiz iş yapıyosun başarılarının devamını dilerim" },
    { name: "Ali Tunç Yiğit", text: "Mehmet ustamın ellerine sağlık kombimi en hızlı şekilde arızayı tesbit edip arızayı giderdi" },
    { name: "Rüştü Yılmazer", text: "Eline koluna sağlık abim 7/24 hızır gibisin" },
    { name: "Tolga Han Pars", text: "Güvenilir hesaplı işinde uzman bi usta" },
    { name: "Muhittin Bilgen", text: "İşinde uzman ve özverili çalışmaların çok memnunum.Rabbim işlerini rast getirsin." },
    { name: "İsmail Güler", text: "Harika işçilik, çok memnun kaldım" },
    { name: "Osman Çavuş", text: "İşini iyi yapan dürüst güvenilir hesaplı usta" },
    { name: "Coskun Uzunoglu", text: "Güvenilir, hızlı ve profesyonel işçilik" },
    { name: "Hacer Ay", text: "Teşekkür ederim, her şey mükemmel" },
    { name: "Tayfun Agurtlu", text: "Kombi bakım arızasını giderdi hayırlı işler" }
  ];

  const galleryImages = [
        "https://toyteknik.com/resimler/8.jpg",
    "https://toyteknik.com/resimler/9.jpg",
    "https://toyteknik.com/resimler/10.jpg",
    "https://toyteknik.com/resimler/11.jpg",
    "https://toyteknik.com/resimler/1.jpg",
    "https://toyteknik.com/resimler/2.jpg",
    "https://toyteknik.com/resimler/3.jpg",
    "https://toyteknik.com/resimler/4.jpg",
    "https://toyteknik.com/resimler/5.jpg",
    "https://toyteknik.com/resimler/6.jpg",
    "https://toyteknik.com/resimler/7.jpg",
 ];

  return (
    <div className="min-h-screen font-poppins relative">
      <Helmet>
        <title>Toy Teknik - Kombi ve Beyaz Eşya Servisi</title>
        <meta name="description" content="Bursa'nın güvenilir kombi ve beyaz eşya servisi Toy Teknik" />
        <meta name="keywords" content="toy teknik, kombi servisi, beyaz eşya servisi, bursa, kestel" />
      </Helmet>

      {/* Fixed Background Image */}
      <div className="fixed inset-0 z-0">
        <img 
          src="https://i.pinimg.com/564x/fc/bc/33/fcbc3349150cdd755570d78fd5c8a026.jpg" 
          alt="Background" 
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black/20 backdrop-blur-md" />
      </div>

      {/* Content Container */}
      <div className="relative z-10 max-w-6xl mx-auto px-4">
{/* Header */}
<header className={`fixed left-0 right-0 z-50 transition-all duration-300 ${isScrolled ? 'bg-slate-900/95 backdrop-blur-md shadow-lg' : 'bg-transparent'}`}>
  <div className="max-w-7xl mx-auto px-4">
    <div className="grid grid-cols-3 items-center h-16">
      {/* Logo - Left */}
      <div className="text-white">
        <a href="#hero" className="flex items-center space-x-2">
          <Wrench className="w-7 h-7 text-teal-400" />
          <span className="text-lg font-bold">Toy Teknik</span>
        </a>
      </div>

      {/* Navigation - Center */}
      <nav className="hidden md:flex items-center justify-center">
        <div className="flex space-x-1">
          <a href="#services" className="px-3 py-1.5 text-sm font-medium text-gray-300 hover:text-white hover:bg-teal-500/20 rounded-md transition-all duration-200">
            Hizmetler
          </a>
          <a href="#reviews-intro" className="px-3 py-1.5 text-sm font-medium text-gray-300 hover:text-white hover:bg-teal-500/20 rounded-md transition-all duration-200">
            Yorumlar
          </a>
          <a href="#gallery" className="px-3 py-1.5 text-sm font-medium text-gray-300 hover:text-white hover:bg-teal-500/20 rounded-md transition-all duration-200">
            Galeri
          </a>
          <a href="#posts" className="px-3 py-1.5 text-sm font-medium text-gray-300 hover:text-white hover:bg-teal-500/20 rounded-md transition-all duration-200">
            Gönderiler
          </a>
          <a href="#contact" className="px-3 py-1.5 text-sm font-medium text-gray-300 hover:text-white hover:bg-teal-500/20 rounded-md transition-all duration-200">
            İletişim
          </a>
        </div>
      </nav>

      {/* CTA Button - Right */}
      <div className="hidden md:flex justify-end">
        <a href="tel:+905325691067" className="flex items-center space-x-2 bg-teal-500 hover:bg-teal-600 text-white px-4 py-1.5 rounded-lg transition-colors duration-200">
          <Phone className="w-4 h-4" />
          <span className="font-medium">532 569 10 67</span>
        </a>
      </div>

      {/* Mobile Menu Button */}
      <button 
        className="md:hidden text-white justify-end absolute right-4"
        onClick={() => setIsMenuOpen(!isMenuOpen)}
        aria-label="Menu"
      >
        {isMenuOpen ? 
          <X className="w-6 h-6" /> : 
          <div className="w-6 h-6 flex flex-col justify-between items-center">
            <span className="block w-6 h-1 bg-white"></span>
            <span className="block w-6 h-1 bg-white"></span>
            <span className="block w-6 h-1 bg-white"></span>
          </div>
        }
      </button>
    </div>

    {/* Mobile Menu */}
    {isMenuOpen && (
      <div className="md:hidden bg-slate-800/95 backdrop-blur-md rounded-lg mt-2 p-3">
        <div className="space-y-1">
          <a href="#services" className="block px-3 py-2 text-base font-medium text-gray-300 hover:text-white hover:bg-teal-500/20 rounded-md transition-all duration-200">
            Hizmetler
          </a>
          <a href="#reviews-intro" className="block px-3 py-2 text-base font-medium text-gray-300 hover:text-white hover:bg-teal-500/20 rounded-md transition-all duration-200">
            Yorumlar
          </a>
          <a href="#gallery" className="block px-3 py-2 text-base font-medium text-gray-300 hover:text-white hover:bg-teal-500/20 rounded-md transition-all duration-200">
            Galeri
          </a>
          <a href="#posts" className="block px-3 py-2 text-base font-medium text-gray-300 hover:text-white hover:bg-teal-500/20 rounded-md transition-all duration-200">
            Gönderiler
          </a>
          <a href="#contact" className="block px-3 py-2 text-base font-medium text-gray-300 hover:text-white hover:bg-teal-500/20 rounded-md transition-all duration-200">
            İletişim
          </a>
          <a href="tel:+905325691067" className="flex items-center space-x-2 bg-teal-500 text-white px-3 py-2 rounded-lg mt-2">
            <Phone className="w-4 h-4" />
            <span>532 569 10 67</span>
          </a>
        </div>
      </div>
    )}
  </div>
</header>


        {/* Hero Section */}
        <div className="min-h-screen flex items-center pt-24 mb-8" id="hero" ref={el => sections.current.hero = el}>
          <div className="w-full bg-white/10 backdrop-blur-md p-8 rounded-2xl">
            <div className="grid md:grid-cols-2 gap-12 items-center">
              {/* Left Column - Text Content */}
              <div className="space-y-8">
                <div className="space-y-6">
                  <h1 className="text-4xl md:text-6xl font-bold text-white leading-tight">
                    Toy Teknik 
                    <span className="block text-teal-400 mt-2">
                      Kombi ve Beyaz Eşya Servisi
                    </span>
                  </h1>
                  <p className="text-xl text-gray-300 leading-relaxed">
                    Kaliteli, güvenilir ve hızlı tamir hizmetleriyle Bursa'nın tercih edilen servisi!
                  </p>
                </div>

                {/* CTA Buttons */}     
                <div className="grid grid-cols-2 gap-2">
                  <a 
                    href="https://wa.me/905325691067" 
                    className="flex items-center justify-center gap-2 bg-[#25D366] bg-opacity-30 border-2 border-white text-white px-4 py-4 rounded-md hover:bg-[#128C7E] transition-all duration-300 transform hover:scale-105 shadow-lg w-full"
                  >
                    <MessageCircle className="w-5 h-5 text-white" />
                    <span className="font-medium text-white">Whatsapp'tan yazın</span>
                  </a>
                  <a 
                    href="tel:+905325691067" 
                    className="flex items-center justify-center gap-2 bg-teal-500 bg-opacity-30 border-2 border-white text-white px-4 py-4 rounded-md hover:bg-teal-600 transition-all duration-300 transform hover:scale-105 shadow-lg w-full"
                  >
                    <Phone className="w-5 h-5 text-white" />
                    <span className="font-medium text-white">Bizi arayın</span>
                  </a>
                </div>

                {/* Feature Grid */}
                <div className="grid grid-cols-3 gap-2">
                  <div className="flex items-center justify-center gap-2 text-gray-300 bg-gray-800 bg-opacity-40 border border-gray-400 rounded-md p-2">
                    <Shield className="w-5 h-5 text-gray" />
                    <span className="text-gray">1 Yıl Garanti</span>
                  </div>
                  <div className="flex items-center justify-center gap-2 text-gray-300 bg-gray-800 bg-opacity-40 border border-gray-400 rounded-md p-2">
                    <ThumbsUp className="w-5 h-5 text-gray" />
                    <span className="text-gray">1000+ Mutlu Müşteri</span>
                  </div>
                  <div className="flex items-center justify-center gap-2 text-gray-300 bg-gray-800 bg-opacity-40 border border-gray-400 rounded-md p-2">
                    <Wrench className="w-5 h-5 text-gray" />
                    <span className="text-gray">Profesyonel Hizmet</span>
                  </div>
                </div>
              </div>

              {/* Right Column - Image */}
              <div className="relative">
                <div className="absolute inset-0 bg-teal-500/20 rounded-2xl blur-3xl" />
                <img 
                  src="https://ToyTeknik.com/resimler/usta.jpg" 
                  alt="Ustamız" 
                  className="relative rounded-2xl shadow-2xl w-full h-full object-cover border-2 border-teal-500/20"
                />
              </div>
            </div>
          </div>
        </div>

        {/* Services Section */}
        <div className="mb-8" ref={el => sections.current.services = el}>
          <div id="services" className="bg-white/10 backdrop-blur-md rounded-2xl p-8">
            <div className="grid md:grid-cols-3 gap-8">
              {services.map((service, index) => (
                <div key={index} className="bg-slate-800/50 backdrop-blur-lg p-6 rounded-xl border border-slate-700 hover:border-teal-500 transition flex flex-col">
                  <img src={service.image} alt={service.title} className="w-full h-60 object-cover rounded-xl mb-4 content-center" />
                  <h3 className="text-xl font-semibold mb-4 text-white flex justify-center items-center">{service.title}</h3>
                  <p className="text-gray-300 flex-grow flex justify-center items-center">{service.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Müşteri Yorumları ve Tanıtım Section */}
        <div className="mb-16" ref={el => sections.current['reviews-intro'] = el}>
          <div id="reviews-intro" className="bg-white/10 backdrop-blur-md rounded-2xl p-8">
            <div className="grid grid-cols-1 md:grid-cols-12 gap-8">
              {/* Tanıtım Metni */}
              <div className="md:col-span-4 flex flex-col justify-center">
                <h2 className="text-3xl mb-4 text-white font-semibold">Binden Fazla Mutlu Müşterimizle Bir Yıl Parça Garantisi</h2>
                <h3 className="text-2xl mb-4 text-teal-400">Doğru Teşhis, Hızlı Çözüm</h3>
                <p className="text-lg text-gray-300">
                  Bursa içerisinde Kestel, Gürsu, Yıldırım, Osmangazi ve diğer bir çok bölgede çözüme kavuşturduğumuz kombi, çamaşır makinesi ve bulaşık makinesi arızalarımızla profesyonel, güvenilir ve uygun fiyatlı hizmet vermeye devam ediyoruz. Fiyat almak için hemen bizi arayın.
                </p>
              </div>

              {/* Müşteri Yorumları */}
              <div className="md:col-span-8">
                <AnimatedMasonryReviews reviews={reviews} />
              </div>
            </div>
          </div>
        </div>

        {/* Gallery Section */}
<div className="mb-16" ref={el => sections.current.gallery = el}>
  <div id="gallery" className="bg-white/10 backdrop-blur-md rounded-2xl p-8">
    <MasonryGallery images={galleryImages} />
  </div>
</div>

        {/* Posts Section */}
        <div className="mb-16" ref={el => sections.current.posts = el}>
          <div id="posts" className="bg-white/10 backdrop-blur-md rounded-2xl p-8">
            <h2 className="text-3xl text-center mb-12 text-white">Gönderiler</h2>
            <div className="grid md:grid-cols-3 gap-8">
              {/* Combined campaign card */}
              <div className="bg-slate-800/50 backdrop-blur-lg p-6 rounded-xl border border-slate-700 hover:border-teal-500 transition">
                <h3 className="text-xl font-semibold text-white mb-4">Kampanyalı Fiyatlar</h3>
                <ul className="space-y-3">
                  <li className="text-gray-300">Kombi Bakımı: 1000 TL</li>
                  <li className="text-gray-300">Petek Temizliği: 1000 TL</li>
                  <li className="text-white font-bold">İkisi Birlikte: 1500 TL</li>
                </ul>
              </div>
              
              {/* Placeholder card */}
              {/* Uncomment and add more posts as needed
              <div className="bg-slate-800/50 backdrop-blur-lg p-6 rounded-xl border border-slate-700 hover:border-teal-500 transition flex items-center justify-center">
                <p className="text-gray-300">Daha Fazla Gönderi Eklenebilir</p>
              </div>
              */}
            </div>
          </div>
        </div>

        {/* Contact Section */}
        <div className="mb-16" ref={el => sections.current.contact = el}>
          <div id="contact" className="bg-white/10 backdrop-blur-md rounded-2xl p-8">
            <div className="grid md:grid-cols-2 gap-2">
              <div className="flex flex-col items-center justify-center text-center">
                <p className="text-lg text-white mb-4">Mehmet TOY</p>
                <div className="flex gap-4 mb-4">
                  <Phone className="w-6 h-6 text-teal-400" />
                  <a href="tel:+905325691067" className="text-lg text-white">0532 569 10 67</a>
                </div>
                <div className="flex items-center gap-4">
                  <MapPin className="w-6 h-6 text-teal-400" />
                  <p className="text-lg text-white">
                    Kale Mahallesi, Barboros Caddesi<br />
                    No:22/1 Kestel / Bursa
                  </p>
                </div>
              </div>
              <div className="h-64 rounded-xl overflow-hidden">
                <iframe 
                  width="100%" 
                  height="100%" 
                  frameBorder="0" 
                  scrolling="no" 
                  marginHeight="0" 
                  marginWidth="0" 
                  src="https://maps.google.com/maps?width=100%25&amp;height=700&amp;hl=tr&amp;q=Kale%20mahallesi,%20barboros%20caddesi,%20no:22/1%20Kestel%20/%20Bursa+(Toy%20Teknik)&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                  title="Toy Teknik Konum"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Footer */}
      <footer className="relative z-10 bg-slate-900/80 backdrop-blur-md text-white py-8">
        <div className="max-w-6xl mx-auto px-4 text-center">
          <p>© 2024 Toy Teknik. Tüm hakları saklıdır.</p>
        </div>
      </footer>

      {/* Whatsapp and Phone Buttons */}
      <div className="fixed bottom-4 right-4 z-50 space-y-2">
        <a
          href="https://wa.me/905325691067"
          target="_blank"
          rel="noreferrer noopener"
          className="flex items-center justify-center w-14 h-14 rounded-full bg-[#25d366]"
        >
          <svg fill="#fff" height="24px" width="24px" viewBox="0 0 308.00 308.00">
            <path d="M227.904,176.981c-0.6-0.288-23.054-11.345-27.044-12.781c-1.629-0.585-3.374-1.156-5.23-1.156 c-3.032,0-5.579,1.511-7.563,4.479c-2.243,3.334-9.033,11.271-11.131,13.642c-0.274,0.313-0.648,0.687-0.872,0.687 c-0.201,0-3.676-1.431-4.728-1.888c-24.087-10.463-42.37-35.624-44.877-39.867c-0.358-0.61-0.373-0.887-0.376-0.887 c0.088-0.323,0.898-1.135,1.316-1.554c1.223-1.21,2.548-2.805,3.83-4.348c0.607-0.731,1.215-1.463,1.812-2.153 c1.86-2.164,2.688-3.844,3.648-5.79l0.503-1.011c2.344-4.657,0.342-8.587-0.305-9.856c-0.531-1.062-10.012-23.944-11.02-26.348 c-2.424-5.801-5.627-8.502-10.078-8.502c-0.413,0-0.815,0-1.732,0.073c-2.109,0.089-13.594,1.601-18.672,4.802 c-5.385,3.395-14.495,14.217-14.495,33.249c0,17.129,10.87,33.302,15.537,39.453c0.116,0.155,0.329,0.47,0.638,0.922 c17.873,26.102,40.154,45.446,62.741,54.469c21.745,8.686,32.042,9.69,37.896,9.69c0.001,0,0.001,0,0.001,0 c2.46,0,4.429-0.193,6.166-0.364l1.102-0.105c7.512-0.666,24.02-9.22,27.775-19.655c2.958-8.219,3.738-17.199,1.77-20.458 C233.168,179.508,230.845,178.393,227.904,176.981z"/>
            <path d="M156.734,0C73.318,0,5.454,67.354,5.454,150.143c0,26.777,7.166,52.988,20.741,75.928L0.212,302.716 c-0.484,1.429-0.124,3.009,0.933,4.085C1.908,307.58,2.943,308,4,308c0.405,0,0.813-0.061,1.211-0.188l79.92-25.396 c21.87,11.685,46.588,17.853,71.604,17.853C240.143,300.27,308,232.923,308,150.143C308,67.354,240.143,0,156.734,0z M156.734,268.994c-23.539,0-46.338-6.797-65.936-19.657c-0.659-0.433-1.424-0.655-2.194-0.655c-0.407,0-0.815,0.062-1.212,0.188 l-40.035,12.726l12.924-38.129c0.418-1.234,0.209-2.595-0.561-3.647c-14.924-20.392-22.813-44.485-22.813-69.677 c0-65.543,53.754-118.867,119.826-118.867c66.064,0,119.812,53.324,119.812,118.867 C276.546,215.678,222.799,268.994,156.734,268.994z"/>
          </svg>
        </a>
        <a
          href="tel:+905325691067"
          className="flex items-center justify-center w-14 h-14 rounded-full bg-teal-500"
        >
          <Phone className="w-6 h-6 text-white" />
        </a>
      </div>
    </div>
  );
};

export default ToyTeknik;