import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Sparkle } from 'lucide-react';

const AutoScrollMasonryReviews = ({ reviews }) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  
  const distributedReviews = useMemo(() => {
    const leftColumn = [];
    const rightColumn = [];
    let leftHeight = 0;
    let rightHeight = 0;
    
    const getEstimatedHeight = (review) => {
      const baseHeight = 100; // Temel yüksekliği azalttık
      const textLength = review.text.length;
      const textHeight = Math.ceil(textLength / 50) * 24; // Text yüksekliğini azalttık
      return baseHeight + textHeight;
    };

    [...reviews, ...reviews].forEach((review) => {
      const estimatedHeight = getEstimatedHeight(review);
      
      if (leftHeight <= rightHeight) {
        leftColumn.push(review);
        leftHeight += estimatedHeight;
      } else {
        rightColumn.push(review);
        rightHeight += estimatedHeight;
      }
    });

    return { leftColumn, rightColumn };
  }, [reviews]);

  // RAF ve timing logic'i useCallback ile optimize edildi
  const animateScroll = useCallback((timestamp, prevTimestamp, prevPosition, animationFrameId) => {
    if (!prevTimestamp) {
      requestAnimationFrame((newTimestamp) => 
        animateScroll(newTimestamp, timestamp, scrollPosition, animationFrameId)
      );
      return;
    }

    const deltaTime = timestamp - prevTimestamp;
    const scrollSpeed = 30; // Scroll hızını azalttık
    const scrollDelta = (deltaTime * scrollSpeed) / 1000;
    
    const totalHeight = reviews.length * 130; // Total height'ı azalttık
    const newPosition = (prevPosition + scrollDelta) % totalHeight;
    
    setScrollPosition(newPosition);
    
    requestAnimationFrame((newTimestamp) => 
      animateScroll(newTimestamp, timestamp, newPosition, animationFrameId)
    );
  }, [reviews.length]);

  useEffect(() => {
    const animationFrameId = { current: null };
    animationFrameId.current = requestAnimationFrame((timestamp) => 
      animateScroll(timestamp, null, scrollPosition, animationFrameId)
    );

    return () => {
      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current);
      }
    };
  }, [animateScroll]);

  const renderReview = useCallback((review, index) => (
    <div
      key={`${review.name}-${index}`}
      className="break-inside-avoid bg-slate-800/50 backdrop-blur-lg p-3 rounded-xl border border-slate-700 hover:border-teal-500 transition mb-3"
    >
      <div className="flex justify-between items-center mb-1.5">
        <p className="text-white font-semibold text-sm">{review.name}</p>
        <div className="flex gap-0.5">
          {Array.from({ length: 5 }).map((_, i) => (
            <Sparkle key={i} className="w-3 h-3 text-yellow-400" />
          ))}
        </div>
      </div>
      <p className="text-gray-300 text-sm leading-relaxed">{review.text}</p>
    </div>
  ), []);

  return (
    <div className="md:col-span-8">
      <div className="relative h-[450px] overflow-hidden"> {/* Height'ı azalttık */}
        <div 
          className="absolute inset-0 grid grid-cols-2 gap-3 px-2"
          style={{
            transform: `translateY(-${scrollPosition}px)`,
            willChange: 'transform',
            transition: 'transform 0.1s linear' // Smooth transition ekledik
          }}
        >
          {/* Sol Kolon */}
          <div className="space-y-3">
            {distributedReviews.leftColumn.map((review, index) => 
              renderReview(review, `left-${index}`)
            )}
          </div>

          {/* Sağ Kolon */}
          <div className="space-y-3">
            {distributedReviews.rightColumn.map((review, index) => 
              renderReview(review, `right-${index}`)
            )}
          </div>
        </div>
      </div>
      <div className="absolute rounded-xl top-0 left-0 right-0 h-12 bg-gradient-to-b from-slate-900 to-transparent pointer-events-none" />
      <div className="absolute rounded-xl bottom-0 left-0 right-0 h-12 bg-gradient-to-t from-slate-900 to-transparent pointer-events-none" />
    </div>
  );
};

export default AutoScrollMasonryReviews;