import React, { useEffect, useState } from 'react';

const MasonryGallery = ({ images }) => {
  const [columns, setColumns] = useState(4);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 640) {
        setColumns(2);
      } else if (window.innerWidth < 1024) {
        setColumns(3);
      } else {
        setColumns(4);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const createColumns = () => {
    const columnsArray = Array.from({ length: columns }, () => []);

    images.forEach((image, index) => {
      const columnIndex = index % columns;
      columnsArray[columnIndex].push(image);
    });
    return columnsArray;
  };

  return (
    <div className="relative">
      <div
        className={`grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 ${
          !expanded ? 'h-[600px] overflow-hidden' : 'h-auto'
        }`}
      >
        {createColumns().map((column, columnIndex) => (
          <div key={columnIndex} className="flex flex-col gap-4">
            {column.map((image, imageIndex) => (
              <div
                key={imageIndex}
                className="group relative overflow-hidden rounded-lg transition-all duration-300 hover:scale-105"
              >
                <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black/50 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                <img
                  src={image}
                  alt={`Gallery item ${columnIndex}-${imageIndex}`}
                  className="w-full h-full object-cover rounded-lg"
                />
              </div>
            ))}
          </div>
        ))}
      </div>
      {!expanded && (
        <div className="absolute bottom-4 left-0 right-0 flex justify-center">
          <button
            onClick={() => setExpanded(true)}
            className="px-6 py-2 bg-white text-gray-800 rounded-full shadow-lg hover:bg-gray-100 transition-colors duration-300 font-medium"
          >
            Daha fazla göster
          </button>
        </div>
      )}
    </div>
  );
};

export default MasonryGallery;